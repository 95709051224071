import React from "react";

function Footer() {
  return (
    <footer className="bg-darkBlue p-6">
      <div className="max-w-screen-xl mx-auto text-center text-white">
        <p>&copy; {new Date().getFullYear()} Blueblockss. All Rights Reserved.</p>
        <div className="space-x-4 mt-2">
          <a href="#" className="hover:text-blue-400">Privacy Policy</a>
          <a href="#" className="hover:text-blue-400">Terms of Service</a>
        </div>
        <div className="mt-4 flex justify-center space-x-6">
          <a href="#" className="text-white hover:text-blue-400">Facebook</a>
          <a href="#" className="text-white hover:text-blue-400">Twitter</a>
          <a href="#" className="text-white hover:text-blue-400">LinkedIn</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
