import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { IconHome, IconBriefcase, IconPlus, IconUser, IconPhoneCall } from '@tabler/icons-react';

function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Desktop and tablet navigation */}
      <nav className="bg-primaryBlue p-4 sticky top-0 z-50">
        <div className="max-w-screen-xl mx-auto flex items-center justify-between">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <img
              src="/logo1.png"
              className="h-8"
              alt="Logo"
            />
          </Link>

          {/* Hamburger Menu for Mobile */}
          <button
            onClick={toggleSidebar}
            className="text-white block md:hidden"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>

          {/* Links for Desktop */}
          <div className="hidden md:flex md:space-x-6">
            <Link to="/" className="text-white hover:bg-blue-600 px-3 py-2 rounded-lg">
              Home
            </Link>
            <Link to="/services" className="text-white hover:bg-blue-600 px-3 py-2 rounded-lg">
              Services
            </Link>
            <Link to="/consultancy" className="text-white hover:bg-blue-600 px-3 py-2 rounded-lg">
              Consultation
            </Link>
            <Link to="/Products" className="text-white hover:bg-blue-600 px-3 py-2 rounded-lg">
              Products
            </Link>
           
            <Link to="/about" className="text-white hover:bg-blue-600 px-3 py-2 rounded-lg">
              About
            </Link>
           
          </div>
        </div>
      </nav>

      {/* Sidebar for Mobile */}
      <div
        className={`fixed top-0 left-0 w-64 h-full bg-primaryBlue z-50 transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}
      >
        <div className="flex justify-between items-center p-4">
          <span className="text-white text-2xl font-semibold">Menu</span>
          <button onClick={toggleSidebar} className="text-white">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="mt-4 space-y-4">
          <Link to="/" className="block text-white px-4 py-2 hover:bg-blue-600">
            Home
          </Link>
          <Link to="/services" className="block text-white px-4 py-2 hover:bg-blue-600">
            Services
          </Link>
          <Link to="/consultancy" className="block text-white px-4 py-2 hover:bg-blue-600">
            Consultation
          </Link>
          <Link to="/Products" className="block text-white px-4 py-2 hover:bg-blue-600">
            Products
          </Link>
          <Link to="/about" className="block text-white px-4 py-2 hover:bg-blue-600">
            About
          </Link>
        </div>
      </div>

      {/* Mobile Navigation (Only visible on mobile) */}
      <div className="md:hidden fixed z-50 w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-0 left-1/2 dark:bg-gray-700 dark:border-gray-600">
        <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
          
          {/* Home Icon */}
          <Link to="/" className="inline-flex flex-col items-center justify-center px-5 rounded-s-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
            <IconHome className="w-6 h-6 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" />
            <span className="sr-only">Home</span>
          </Link>

          {/* Services Icon */}
          <Link to="/services" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
            <IconBriefcase className="w-6 h-6 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" />
            <span className="sr-only">Services</span>
          </Link>

          {/* Middle Button for Book Consultancy */}
          <div className="flex items-center justify-center">
            <Link to="/Book-consultancy" className="inline-flex items-center justify-center w-10 h-10 font-medium bg-blue-600 rounded-full hover:bg-blue-700 group focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
              <IconPlus className="w-5 h-5 text-white" />
              <span className="sr-only">Book Consultancy</span>
            </Link>
          </div>

          {/* About Icon */}
          <Link to="/about" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
            <IconUser className="w-6 h-6 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" />
            <span className="sr-only">About</span>
          </Link>

          {/* Contact Icon */}
          <Link to="/Book-consultancy" className="inline-flex flex-col items-center justify-center px-5 rounded-e-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
            <IconPhoneCall className="w-6 h-6 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" />
            <span className="sr-only">Contact</span>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Nav;
