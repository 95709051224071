// src/components/services/ProductFeatureCard.js

import React from "react";

const ProductFeatureCard = ({ title, icon }) => {
  return (
    <div className="bg-white shadow-md p-4 text-left rounded-lg transition-all duration-300 w-full max-w-lg flex items-center">
      <div className="text-3xl text-primaryBlue mr-4">{icon}</div>
      <h3 className="text-lg font-bold">{title}</h3>
    </div>
  );
};

export default ProductFeatureCard;
