// src/components/common/IconCard.js

import React from "react";

const IconCard = ({ title, icon }) => {
  return (
    <div className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-md">
      <div className="bg-gray-300 w-24 h-24 mb-4 rounded-full flex items-center justify-center">
        {/* Placeholder for Icon */}
        <img src={icon} alt={title} className="w-12 h-12" />
      </div>
      <h4 className="text-xl font-bold text-primaryBlue">{title}</h4>
    </div>
  );
};

export default IconCard;
