// src/pages/EducationProductDetails.js

import React, { useState } from "react";
import {
  FaVideo,
  FaDesktop,
  FaHandPaper,
  FaFileUpload,
  FaFolderOpen,
  FaTasks,
  FaClipboardCheck,
  FaPoll,
  FaMobileAlt,
  FaChalkboardTeacher,
  FaCloud
} from "react-icons/fa";
import Layout from "../components/Layout/Layout";

const keyBenefits = [
  {
    title: "Video Conferencing",
    description:
      "Fast, reliable, and secure video meetings you can trust. Create a free meeting room, invite guests, and start your first meeting in minutes. An online meeting with video builds trust and increases collaboration.",
    icon: <FaVideo />
  },
  {
    title: "Screen Sharing",
    description:
      "Keep everyone on the same page by sharing your screen so they can see the same content. Sharing slide as a virtual background.",
    icon: <FaDesktop />
  },
  {
    title: "Hand Raise",
    description:
      "Gain the attention of the presenter. The host will be notified that you’ve raised your hand.",
    icon: <FaHandPaper />
  },
  {
    title: "File Sharing",
    description:
      "Easily share files and resources with students, ensuring they have access to all necessary materials.",
    icon: <FaFileUpload />
  },
  {
    title: "Manage Content",
    description:
      "Organize and distribute course content effectively, ensuring smooth delivery of educational resources.",
    icon: <FaFolderOpen />
  },
  {
    title: "Assignment",
    description:
      "Create and manage assignments with ease, allowing educators to provide and track student tasks efficiently.",
    icon: <FaTasks />
  },
  {
    title: "Quiz",
    description:
      "Conduct quizzes to assess student understanding and measure their progress in real time.",
    icon: <FaClipboardCheck />
  },
  {
    title: "Poll",
    description:
      "Gather real-time responses through polls, engaging students and gaining insights instantly.",
    icon: <FaPoll />
  },
  {
    title: "Multi-Platform Support",
    description:
      "Access the platform from any device, ensuring seamless learning regardless of location.",
    icon: <FaMobileAlt />
  }
];

const classModeDetails = {
  title: "Class Mode",
  description:
    "Class Mode is designed for teacher-led structured learning sessions. Teachers control what students can access and interact with during class.",
  characteristics: [
    { title: "Teacher Control", description: "Teachers can control device functions and limit distractions." },
    { title: "Secure", description: "Provides a secure, focused learning environment." },
    { title: "Content Push", description: "Push content and control access to resources during class." }
  ],
  image: "path_to_class_mode_image_placeholder"
};

const cloudModeDetails = {
  title: "Cloud Mode",
  description:
    "Cloud Mode enables flexible learning from anywhere. Teachers and students can access course content and interactive tools from any device, supporting both real-time and asynchronous learning.",
  characteristics: [
    { title: "Flexible Learning", description: "Enables both real-time and asynchronous learning." },
    { title: "Secure Access", description: "Provides secure access to cloud resources." },
    { title: "Device Integration", description: "Seamlessly integrates with multiple devices." }
  ],
  image: "path_to_cloud_mode_image_placeholder"
};

const documentations = [
  { title: "Setup Guide for Classroom Management", link: "#", icon: <FaFileUpload /> },
  { title: "User Guide for Classroom Features", link: "#", icon: <FaFolderOpen /> },
  { title: "Cloud Mode Setup and Integration Guide", link: "#", icon: <FaCloud /> },
  { title: "FAQ Guide", link: "#", icon: <FaTasks /> }
];

const faqs = [
  { question: "What is classroom management?", answer: "Classroom management allows teachers to control and manage student devices during a session." },
  { question: "How do I set up cloud mode?", answer: "Follow the Cloud Mode Setup Guide found in the documentation section." },
  { question: "Can students use mobile devices?", answer: "Yes, our platform supports multi-platform access, including mobile devices." },
  { question: "Can I change user type in class mode?", answer: "Yes, user types can be changed in the settings section of the Classroom Management app." }
];

const EducationProductDetails = () => {
  const [expandedFaqIndex, setExpandedFaqIndex] = useState(null);

  const handleFaqClick = (index) => {
    setExpandedFaqIndex(expandedFaqIndex === index ? null : index);
  };

  return (
    <Layout>
      {/* Hero Section */}
      <section className="relative bg-gray-100 py-12">
        <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: "url('path_to_hero_image_placeholder')" }}>
          <div className="bg-primaryBlue bg-opacity-70 h-full w-full"></div>
        </div>
        <div className="relative z-10 max-w-screen-xl mx-auto text-center py-12">
          <h1 className="text-5xl font-bold text-white mb-4">MDM Solutions for Education</h1>
          <p className="text-lg text-white">Empowering educators with robust tools for classroom and remote learning environments.</p>
        </div>
      </section>

      {/* Key Benefits Section */}
      <section className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">Key Benefits</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {keyBenefits.map((benefit, index) => (
              <div key={index} className="bg-white shadow-md p-6 rounded-lg text-center">
                <div className="flex justify-center mb-4">
                  <div className="text-5xl text-primaryBlue">{benefit.icon}</div>
                </div>
                <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Class Mode Section */}
      <section className="py-12 bg-gray-100">
        <div className="max-w-screen-xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">{classModeDetails.title}</h2>
          <p className="text-gray-600 mb-8">{classModeDetails.description}</p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center">
            {/* Image Placeholder */}
            <div className="bg-gray-300 w-full h-64 md:h-80 rounded-lg flex items-center justify-center">
              {/* <p className="text-gray-500">Image Placeholder (640x360)</p> */}
              <img src=""  />

            </div>
            <div className="space-y-4">
              {classModeDetails.characteristics.map((char, index) => (
                <div key={index} className="flex items-center">
                  <FaChalkboardTeacher className="text-primaryBlue text-2xl mr-3" />
                  <p><strong>{char.title}:</strong> {char.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Cloud Mode Section */}
      <section className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">{cloudModeDetails.title}</h2>
          <p className="text-gray-600 mb-8">{cloudModeDetails.description}</p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center">
            <div className="space-y-4">
              {cloudModeDetails.characteristics.map((char, index) => (
                <div key={index} className="flex items-center">
                  <FaCloud className="text-primaryBlue text-2xl mr-3" />
                  <p><strong>{char.title}:</strong> {char.description}</p>
                </div>
              ))}
            </div>
            {/* Image Placeholder */}
            <div className="bg-gray-300 w-full h-64 md:h-80 rounded-lg flex items-center justify-center">
              {/* <p className="text-gray-500">Image Placeholder (640x360)</p> */}
              <img src=""  />

            </div>
          </div>
        </div>
      </section>

      {/* Documentations Section */}
      <section className="py-12 bg-gray-100">
        <div className="max-w-screen-xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8">Documentations</h2>
          <ul className="space-y-4 text-left mx-auto max-w-2xl">
            {documentations.map((doc, index) => (
              <li key={index} className="bg-white shadow-md p-4 rounded-lg">
                <a href={doc.link} className="text-primaryBlue hover:underline flex items-center">
                  {doc.icon}
                  <span className="ml-2">{doc.title}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-12 bg-gray-100">
        <div className="max-w-screen-xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8">FAQ</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="bg-white shadow-md p-4 rounded-lg text-left cursor-pointer"
                onClick={() => handleFaqClick(index)}
              >
                <h3 className="font-semibold text-primaryBlue">
                  {faq.question} {expandedFaqIndex === index ? "▲" : "▼"}
                </h3>
                {expandedFaqIndex === index && <p className="text-gray-600 mt-2">{faq.answer}</p>}
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default EducationProductDetails;
