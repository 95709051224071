import React, { useEffect, useRef } from 'react';
import { FaAws, FaGoogle, FaPython, FaNodeJs, FaMicrosoft, FaCloud, FaRobot, FaDatabase } from 'react-icons/fa';
import { SiDotnet, SiIbm, SiDocker, SiTensorflow, SiKubernetes, SiPostgresql } from 'react-icons/si';

function TechnologyCarousel() {
  const logos = [
    { icon: <FaAws size={50} />, name: 'AWS' },
    { icon: <FaGoogle size={50} />, name: 'Google Cloud' },
    { icon: <FaPython size={50} />, name: 'Python' },
    { icon: <FaNodeJs size={50} />, name: 'Node.js' },
    { icon: <SiDotnet size={50} />, name: '.NET' },
    { icon: <FaMicrosoft size={50} />, name: 'Microsoft Azure' },
    { icon: <SiTensorflow size={50} />, name: 'TensorFlow' },
    { icon: <FaCloud size={50} />, name: 'Cloud Computing' },
    { icon: <FaRobot size={50} />, name: 'Artificial Intelligence' },
    { icon: <FaDatabase size={50} />, name: 'Big Data' },
    { icon: <SiIbm size={50} />, name: 'IBM Watson' },
    { icon: <SiDocker size={50} />, name: 'Docker' },
    { icon: <SiKubernetes size={50} />, name: 'Kubernetes' },
    { icon: <SiPostgresql size={50} />, name: 'PostgreSQL' },
  ];

  const carouselRef = useRef(null);

  useEffect(() => {
    const scrollContainer = carouselRef.current;
    let scrollAmount = 0;

    const autoScroll = () => {
      if (scrollContainer) {
        scrollAmount += 1; // Speed of scrolling
        if (scrollAmount >= scrollContainer.scrollWidth / 2) {
          scrollAmount = 0; // Reset scroll when it reaches the middle to create a loop
        }
        scrollContainer.scrollTo({
          left: scrollAmount,
          behavior: 'smooth',
        });
      }
    };

    const interval = setInterval(autoScroll, 50); // Adjust the interval for smoother or faster scrolling

    return () => {
      clearInterval(interval); // Clean up interval on unmount
    };
  }, []);

  return (
    <section className="py-16 bg-white">
      <div className="max-w-screen-xl mx-auto text-center px-4">
        <h2 className="text-4xl font-bold text-primaryBlue mb-8">Our Technology Expertise</h2>
        <div className="relative overflow-hidden">
          <div className="carousel-track flex space-x-8" ref={carouselRef}>
            {logos.map((logo, index) => (
              <div key={index} className="carousel-item flex flex-col items-center space-y-2">
                {logo.icon}
                <p className="text-sm font-semibold">{logo.name}</p>
              </div>
            ))}
            {logos.map((logo, index) => (
              // Clone the logos to create a seamless infinite scroll
              <div key={index + logos.length} className="carousel-item flex flex-col items-center space-y-2">
                {logo.icon}
                <p className="text-sm font-semibold">{logo.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechnologyCarousel;
