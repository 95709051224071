// src/pages/GamesCasinoProductDetails.js

import React, { useState } from "react";
import {
  FaGamepad,
  FaMoneyBillWave,
  FaDice,
  FaWallet,
  FaLock,
  FaExchangeAlt,
  FaShieldAlt
} from "react-icons/fa";
import Layout from "../components/Layout/Layout";

const gamesCasinoFeatures = [
  {
    title: "Immersive Gaming Experience",
    description:
      "Enjoy high-quality graphics, immersive sound, and engaging gameplay across multiple game modes.",
    icon: <FaGamepad />
  },
  {
    title: "In-game Currency Management",
    description:
      "Seamlessly manage in-game currency and rewards. Our platform integrates easily with digital wallets for a smooth transaction experience.",
    icon: <FaMoneyBillWave />
  },
  {
    title: "Secure Casino Games",
    description:
      "Our casino games ensure fair play with secure game mechanics, user data privacy, and regulatory compliance.",
    icon: <FaDice />
  },
  {
    title: "Digital Wallet Integration",
    description:
      "Supports MetaMask, WalletConnect, and other popular wallets for easy deposits and withdrawals.",
    icon: <FaWallet />
  },
  {
    title: "Blockchain Security",
    description:
      "Benefit from decentralized security protocols, ensuring safe transactions and game integrity.",
    icon: <FaLock />
  },
  {
    title: "Instant Payouts",
    description:
      "Enjoy instant payouts with our secure blockchain-based transaction system.",
    icon: <FaExchangeAlt />
  }
];

const documentations = [
  { title: "Setup Guide for Digital Wallets", link: "#", icon: <FaWallet /> },
  { title: "User Guide for Casino Games", link: "#", icon: <FaGamepad /> },
  { title: "Security & Compliance Overview", link: "#", icon: <FaShieldAlt /> }
];

const faqs = [
  {
    question: "How do I connect my digital wallet?",
    answer:
      "You can connect your digital wallet using MetaMask, WalletConnect, or any supported wallet through the wallet connection interface."
  },
  {
    question: "Is my personal data secure?",
    answer:
      "Yes, our platform uses advanced encryption and blockchain protocols to ensure your data remains secure."
  },
  {
    question: "How do I make in-game purchases?",
    answer:
      "Simply connect your wallet, select the amount of in-game currency you want to purchase, and confirm the transaction through your wallet."
  }
];

const GamesCasinoProductDetails = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Layout>
      {/* Hero Section */}
      <section className="relative bg-gray-100 py-12">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{ backgroundImage: "url('path_to_hero_image_placeholder')" }}
        >
          <div className="bg-primaryBlue bg-opacity-70 h-full w-full"></div>
        </div>
        <div className="relative z-10 max-w-screen-xl mx-auto text-center py-12">
          <h1 className="text-5xl font-bold text-white mb-4">Games & Casino Apps</h1>
          <p className="text-lg text-white">
            Engage with our secure and immersive gaming and casino solutions, integrated with popular digital wallets.
          </p>
        </div>
      </section>

      {/* Key Features Section */}
      <section className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">Key Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {gamesCasinoFeatures.map((feature, index) => (
              <div key={index} className="bg-white shadow-md p-6 rounded-lg text-center">
                <div className="flex justify-center mb-4">
                  <div className="text-5xl text-primaryBlue">{feature.icon}</div>
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-12 bg-gray-100">
        <div className="max-w-screen-xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">How It Works</h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Step 1: Connect Wallet */}
            <div className="bg-white shadow-md p-6 rounded-lg">
              <div className="bg-gray-300 w-full h-56 rounded-lg flex items-center justify-center mb-4">
                {/* <p className="text-gray-500">Image Placeholder (640x360)</p> */}
                <img src=""  />


              </div>
              <h3 className="text-xl font-bold mb-2">Step 1: Connect Wallet</h3>
              <p className="text-gray-600">
                Connect your preferred digital wallet like MetaMask or WalletConnect to start using in-game features.
              </p>
            </div>

            {/* Step 2: Purchase In-Game Currency */}
            <div className="bg-white shadow-md p-6 rounded-lg">
              <div className="bg-gray-300 w-full h-56 rounded-lg flex items-center justify-center mb-4">
                {/* <p className="text-gray-500">Image Placeholder (640x360)</p> */}
                <img src=""  />

              </div>
              <h3 className="text-xl font-bold mb-2">Step 2: Purchase In-Game Currency</h3>
              <p className="text-gray-600">
                Use your connected wallet to purchase in-game currency or credits, which can be used for playing games or placing bets.
              </p>
            </div>

            {/* Step 3: Play, Win, and Withdraw */}
            <div className="bg-white shadow-md p-6 rounded-lg">
              <div className="bg-gray-300 w-full h-56 rounded-lg flex items-center justify-center mb-4">
                <p className="text-gray-500">Image Placeholder (640x360)</p>
              </div>
              <h3 className="text-xl font-bold mb-2">Step 3: Play, Win, and Withdraw</h3>
              <p className="text-gray-600">
                Enjoy the games, win rewards, and withdraw your earnings directly to your digital wallet.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Documentations Section */}
      <section className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8">Documentations</h2>
          <ul className="space-y-4 text-left mx-auto max-w-2xl">
            {documentations.map((doc, index) => (
              <li key={index} className="bg-gray-100 shadow-md p-4 rounded-lg">
                <a href={doc.link} className="text-primaryBlue hover:underline flex items-center">
                  {doc.icon}
                  <span className="ml-2">{doc.title}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-12 bg-gray-100">
        <div className="max-w-screen-xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8">FAQ</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="bg-white shadow-md p-4 rounded-lg text-left cursor-pointer"
                onClick={() => handleExpand(index)}
              >
                <h3 className="font-semibold text-primaryBlue">
                  {faq.question} {expandedIndex === index ? "▲" : "▼"}
                </h3>
                {expandedIndex === index && <p className="text-gray-600 mt-2">{faq.answer}</p>}
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default GamesCasinoProductDetails;
