import { FaQuoteLeft } from "react-icons/fa";
import { motion } from "framer-motion";

function Testimonials() {
  return (
    <section className="py-16 bg-white" id="testimonials">
      <div className="max-w-screen-xl mx-auto text-center px-4">
        <h2 className="text-4xl font-bold text-primaryBlue mb-12">What Our Clients Say</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          
          {/* Testimonial 1 */}
          <motion.div 
            className="bg-gray-100 rounded-lg p-8 shadow-lg"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <FaQuoteLeft className="text-primaryBlue text-3xl mb-4" />
            <p className="text-gray-700 mb-4">
              "Blueblockss helped us implement AI solutions that automated our workflows and saved us countless hours each week!"
            </p>
            <h4 className="font-semibold">John Doe, CEO at AI Ventures</h4>
          </motion.div>

          {/* Testimonial 2 */}
          <motion.div 
            className="bg-gray-100 rounded-lg p-8 shadow-lg"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <FaQuoteLeft className="text-primaryBlue text-3xl mb-4" />
            <p className="text-gray-700 mb-4">
              "The Blueblockss team streamlined our cloud infrastructure and enabled us to scale faster than ever."
            </p>
            <h4 className="font-semibold">Jane Smith, CTO at Cloud Innovations</h4>
          </motion.div>

          {/* Testimonial 3 */}
          <motion.div 
            className="bg-gray-100 rounded-lg p-8 shadow-lg"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <FaQuoteLeft className="text-primaryBlue text-3xl mb-4" />
            <p className="text-gray-700 mb-4">
              "Their IoT solutions completely transformed our operations, increasing efficiency and reducing costs."
            </p>
            <h4 className="font-semibold">Michael Lee, Operations Head at IoT Solutions</h4>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
