import React from "react";
import { Link } from "react-router-dom";

function WhatWeDo() {
  return (
    <section className="py-16 bg-gray-100" id="what-we-do">
      <div className="max-w-screen-xl mx-auto text-center px-4">
        <h2 className="text-4xl font-bold text-primaryBlue mb-12">What We Do</h2>

        {/* Professional 2x2 Grid Layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <Link to="./Book-consultancy">
          {/* Card 1 - Custom Software Development */}
          <div className="bg-white rounded-lg shadow-lg p-8 transition-shadow hover:shadow-2xl">
            <h3 className="text-2xl font-semibold text-primaryBlue mb-4">Custom Software Development</h3>
            <p className="text-gray-700 mb-4">
              Tailored software solutions designed for seamless integration, optimal performance, and scalable growth.
            </p>
          </div>
          </Link>
          <Link to="./Book-consultancy">
          {/* Card 2 - Web Design & App Development */}
          <div className="bg-white rounded-lg shadow-lg p-8 transition-shadow hover:shadow-2xl">
            <h3 className="text-2xl font-semibold text-primaryBlue mb-4">Web Design & App Development</h3>
            <p className="text-gray-700 mb-4">
              We craft stunning websites and innovative apps, ensuring functionality and user satisfaction.
            </p>
          </div>
          </Link>
          <Link to="./Book-consultancy">
          {/* Card 3 - UX/UI Design */}
          <div className="bg-white rounded-lg shadow-lg p-8 transition-shadow hover:shadow-2xl">
            <h3 className="text-2xl font-semibold text-primaryBlue mb-4">UX/UI Design</h3>
            <p className="text-gray-700 mb-4">
              We deliver intuitive and engaging interfaces, enhancing user experience and driving business success.
            </p>
          </div>
          </Link>
          <Link to="./Book-consultancy">
          {/* Card 4 - IT Consulting */}
          <div className="bg-white rounded-lg shadow-lg p-8 transition-shadow hover:shadow-2xl">
            <h3 className="text-2xl font-semibold text-primaryBlue mb-4">IT Consulting</h3>
            <p className="text-gray-700 mb-4">
              Expert IT consulting services that optimize technology, enhance efficiency, and drive business growth.
            </p>
          </div>
</Link>
        </div>
      </div>
    </section>
  );
}

export default WhatWeDo;
