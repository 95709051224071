import React from "react";
import Layout from "../components/Layout/Layout";
import ProcessTimeline from "../components/Home/ProcessTimeline";
import WhatWeDo from "../components/Home/WhatWeDo";
import ConsultingFocus from "../components/Home/ConsultingFocus";
import Testimonials from "../components/Home/Testimonials";
import ContactForm from "../components/Home/ContactForm";
import { Link } from "react-router-dom";
import TechnologyScroll from "../components/Home/TechnologyScroll";
import TechnologyCarousel from "../components/Home/TechnologyScroll";
import HeroSection from "../components/Home/HeroSection";
import Teams from "../components/Home/Teams";

const Home = () => {
  return (
    <Layout>
      {/* Hero Section */}
      <HeroSection />

      {/* Process Timeline (How We Do It) */}
      <ProcessTimeline />
      <WhatWeDo />
      <TechnologyCarousel />
      <ConsultingFocus />
      {/* Features Section */}
      <section className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-primaryBlue mb-6">
            Why Choose Us?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Feature 1 */}
            <div className="p-6 bg-gray-100 rounded-lg shadow-md">
              <div className="bg-gray-300 w-20 h-20 mx-auto mb-4 rounded-full flex items-center justify-center">
                {/*<p className="text-gray-500">Icon</p>*/}
                <img src="/image/team.png" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Expert Team</h3>
              <p className="text-gray-700">
                Our experienced professionals are dedicated to providing the
                best IT solutions for your business.
              </p>
            </div>
            {/* Feature 2 */}
            <div className="p-6 bg-gray-100 rounded-lg shadow-md">
              <div className="bg-gray-300 w-20 h-20 mx-auto mb-4 rounded-full flex items-center justify-center">
                {/*<p className="text-gray-500">Icon</p>*/}
                <img src="/image/solutions.png" />
              </div>
              <h3 className="text-xl font-semibold mb-2">
                Customized Solutions
              </h3>
              <p className="text-gray-700">
                We tailor our IT services to meet your specific needs and help
                you stay ahead in the market.
              </p>
            </div>
            {/* Feature 3 */}
            <div className="p-6 bg-gray-100 rounded-lg shadow-md">
              <div className="bg-gray-300 w-20 h-20 mx-auto mb-4 rounded-full flex items-center justify-center">
                {/*<p className="text-gray-500">Icon</p>*/}
                <img src="/image/support.png" />
              </div>
              <h3 className="text-xl font-semibold mb-2">24/7 Support</h3>
              <p className="text-gray-700">
                We offer round-the-clock support to ensure your business is
                always running smoothly.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Teams/>
      <Testimonials />
      <ContactForm />
      {/* Call to Action Section */}
      <section className="bg-primaryBlue mx-auto my-auto py-12 rounded-3xl text-white text-center">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-3xl font-bold mb-4">
            Ready to Transform Your IT?
          </h2>
          <p className="text-lg mb-6">
            Contact us today to discuss how we can help your business with
            cutting-edge IT solutions.
          </p>
          <Link
            to="./Book-consultancy"
            className="bg-white text-primaryBlue px-6 py-3 rounded-lg font-semibold hover:bg-gray-100"
          >
             Book Consultancy
              </Link>
        </div>
      </section>
    </Layout>
  );
};

export default Home;
