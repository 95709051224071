import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [status, setStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('loading');

    // Construct the HTML content
    const emailContent = `
      <!DOCTYPE html>
      <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; color: #333; }
          .container { max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ddd; background-color: #f9f9f9; }
          h2 { color: #006eff; }
          .content { margin-bottom: 20px; }
          .footer { text-align: center; font-size: 12px; color: #888; }
        </style>
      </head>
      <body>
        <div class="container">
          <h2>New Contact Message</h2>
          <div class="content">
            <p><strong>Name:</strong> ${formData.name}</p>
            <p><strong>Email:</strong> ${formData.email}</p>
            <p><strong>Subject:</strong> ${formData.subject}</p>
            <p><strong>Message:</strong> ${formData.message}</p>
          </div>
          <div class="footer">
            <p>Thank you for reaching out!</p>
          </div>
        </div>
      </body>
      </html>
    `;

    // Use EmailJS to send the email with HTML content
    emailjs.send(
      'service_18adzp8', // Replace with your EmailJS Service ID
      'template_fhs97ne', // Replace with your EmailJS Template ID
      {
        html: emailContent, // Pass the HTML content as the `html` variable
        subject: `${formData.subject} - Message from ${formData.name}`, // Optional subject
      },
      'j0uGYVd3QJpmQEHt1' // Replace with your EmailJS User ID
    )
    .then(() => {
      setStatus('success');
      setFormData({ name: '', email: '', subject: '', message: '' });
    })
    .catch(() => setStatus('error'));
  };

  return (
    <section className="py-16 bg-white" id="contact">
      <div className="max-w-screen-xl mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-12">

        {/* Contact Details */}
        <div className="space-y-8">
          <h2 className="text-4xl font-bold text-primaryBlue">Contact Us</h2>
          <p className="text-lg text-gray-700">
            We'd love to hear from you! Reach out to us using the form or the following details.
          </p>
          <div className="text-lg text-gray-700">
            <p className="font-semibold">Phone:</p>
            <p className="mb-4">+1 226 552 0083</p>

            <p className="font-semibold">Email:</p>
            <p className="mb-4">info@blueblockss.com</p>

            <p className="font-semibold">Address:</p>
            <p>GTA Toronto, ON, CA</p>
          </div>
        </div>

        {/* Contact Form */}
        <div className="bg-gray-50 p-8 rounded-lg shadow-lg">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="flex flex-col">
              <label htmlFor="name" className="text-left text-gray-700">Your Name</label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-primaryBlue"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="email" className="text-left text-gray-700">Your Email</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-primaryBlue"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="subject" className="text-left text-gray-700">Subject</label>
              <input
                type="text"
                id="subject"
                value={formData.subject}
                onChange={handleChange}
                className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-primaryBlue"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="message" className="text-left text-gray-700">Your Message</label>
              <textarea
                id="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-primaryBlue"
                required
              ></textarea>
            </div>

            <button type="submit" className="bg-primaryBlue text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition">
              Send Message
            </button>

            {/* Status Messages */}
            {status === 'loading' && <p className="text-blue-500 mt-4">Sending...</p>}
            {status === 'success' && <p className="text-green-500 mt-4">Message sent successfully!</p>}
            {status === 'error' && <p className="text-red-500 mt-4">Failed to send message. Please try again.</p>}
          </form>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
