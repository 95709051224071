import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import emailjs from "emailjs-com";
// SMTP.js integration for Hostinger email service with response logging
async function sendEmail(formData) {
  console.log(formData);
  const emailContent = `
    <!DOCTYPE html>
    <html>
    <head>
      <style>
        body {
          font-family: Arial, sans-serif;
          color: #333;
        }
        .container {
          max-width: 600px;
          margin: auto;
          padding: 20px;
          border: 1px solid #ddd;
          background-color: #f9f9f9;
        }
        h2 {
          color: #006eff;
        }
        .content {
          margin-bottom: 20px;
        }
        .footer {
          text-align: center;
          font-size: 12px;
          color: #888;
        }
      </style>
    </head>
    <body>
      <div class="container">
        <h2>New Consultancy Request</h2>
        <div class="content">
          <p><strong>Name:</strong> ${formData.name}</p>
          <p><strong>Email:</strong> ${formData.email}</p>
          <p><strong>Project Title:</strong> ${formData.projectTitle}</p>
          <p><strong>Consultation Interest:</strong> ${formData.consultationInterest}</p>
          <p><strong>Best Time for Appointment:</strong> ${formData.timeOfDay}</p>
          <p><strong>Preferred Contact Method:</strong> ${formData.reachMethod}</p>
          ${formData.phone ? `<p><strong>Phone:</strong> ${formData.phone}</p>` : ""}
          <p><strong>Available Days:</strong> ${formData.daysAvailable.join(", ")}</p>
        </div>
        <div class="footer">
          <p>Thank you for using our service!</p>
        </div>
      </div>
    </body>
    </html>
  `;
    try {
      const response = await emailjs.send(
        "service_18adzp8", // Replace with your EmailJS Service ID
        "template_fhs97ne", // Replace with your EmailJS Template ID
        {
          html: emailContent, // Use `html` parameter to pass the HTML content
          email: formData.email, // Any other data required by your template
          subject: `Consultancy Request from ${formData.name}`, // Add a subject if needed
        },
        "j0uGYVd3QJpmQEHt1" // Replace with your EmailJS User ID
      );
      console.log("Email sent response:", response);
      return response;
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  }
  
  function BookConsultancyForm() {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      projectTitle: '',
      consultationInterest: '',
      timeOfDay: '',
      reachMethod: 'Phone', // Set default value here
      daysAvailable: [],
      phone:'',
    });
  
    const [status, setStatus] = useState(null);
    const [errorLog, setErrorLog] = useState(''); // To capture error logs
  

  
    const handleCheckboxChange = (e) => {
      const { value, checked } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        daysAvailable: checked
          ? [...prevState.daysAvailable, value] // Add day if checked
          : prevState.daysAvailable.filter((day) => day !== value), // Remove day if unchecked
      }));
    };
   // Phone validation state
   const [isPhoneValid, setIsPhoneValid] = useState(true);

   const handleChange = (e) => {
     const { id, value } = e.target;
     
     // Phone validation
     if (id === 'phone') {
       const phonePattern = /^[0-9]{10}$/; // Only allows 10-digit numbers
       setIsPhoneValid(phonePattern.test(value));
     }
     
     setFormData({ ...formData, [id]: value });
   };
    const handleSubmit = async (e) => {
      e.preventDefault();
      setStatus('loading');
  
      if (!formData.name || !formData.email || !formData.projectTitle || !formData.consultationInterest || !formData.timeOfDay || !formData.reachMethod || formData.daysAvailable.length === 0) {
        setStatus('error');
        setErrorLog('All fields are required.');  // Set error log if fields are missing
        return;
      }
      if (!isPhoneValid) {
        setStatus('error');
        setErrorLog('Invalid phone number. Please enter a valid 10-digit phone number.');
        return;
      }
      try {
        const response = await sendEmail(formData);
        setStatus('success');
        console.log("Email sent successfully! Response:", response);
        setErrorLog(''); // Clear the error log on success
        setFormData({
          name: '',
          email: '',
          projectTitle: '',
          consultationInterest: '',
          timeOfDay: '',
          reachMethod: 'Phone', // Set default value here
          daysAvailable: [],
          phone:'',
        });
      } catch (error) {
        setStatus('error');
        console.error("Failed to send email:", error);
        setErrorLog(`Error: ${error.message || 'Something went wrong. Please try again.'}`);  // Capture and log the error message
      }
    };
  
  return (
    <Layout>
      <section className="py-16 bg-gray-100" id="book-consultancy">
        <div className="max-w-screen-lg mx-auto px-4">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-4xl font-bold text-primaryBlue mb-8 text-center">Book a Consultancy</h2>
            
            {/* Form Container */}
            <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6">
              
              {/* Left Side Form Fields */}
              <div className="space-y-6">
                {/* Name */}
                <div className="flex flex-col">
                  <label htmlFor="name" className="text-left text-gray-700 font-semibold">Name *</label>
                  <input
                    type="text"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-primaryBlue"
                    required
                  />
                </div>
                
                {/* Email */}
                <div className="flex flex-col">
                  <label htmlFor="email" className="text-left text-gray-700 font-semibold">Email *</label>
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-primaryBlue"
                    required
                  />
                </div>

                <div className="flex flex-col">
                <label htmlFor="phone" className="text-left text-gray-700 font-semibold">Phone</label>
                <input
                  type="text"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className={`border p-3 rounded-lg focus:outline-none focus:border-primaryBlue ${
                    isPhoneValid ? 'border-gray-300' : 'border-red-500'
                  }`}
                />
                {!isPhoneValid && (
                  <span className="text-red-500 text-sm mt-1">Please enter a valid 10-digit phone number.</span>
                )}
              </div>

                
                {/* Project Title */}
                <div className="flex flex-col">
                  <label htmlFor="projectTitle" className="text-left text-gray-700 font-semibold">Project Title *</label>
                  <input
                    type="text"
                    id="projectTitle"
                    value={formData.projectTitle}
                    onChange={handleChange}
                    className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-primaryBlue"
                    required
                  />
                </div>

                {/* Consultation Interest Dropdown */}
                <div className="flex flex-col">
                  <label htmlFor="consultationInterest" className="text-left text-gray-700 font-semibold">Consultation Interest *</label>
                  <select
                    id="consultationInterest"
                    value={formData.consultationInterest}
                    onChange={handleChange}
                    className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-primaryBlue"
                    required
                  >
                    <option value="">Select Interest</option>
                    <option value="Custom Software Development">Custom Software Development</option>
                    <option value="Web Design and App Development">Web Design and App Development</option>
                    <option value="Mobile App Development">Mobile App Development</option>
                    <option value="IT Consulting">IT Consulting</option>
                    <option value="AI & IoT Solutions">AI & IoT Solutions</option>
                    <option value="Workplace Solutions">Workplace Solutions</option>
                    <option value="LMS Solutions">LMS Solutions</option>
                    <option value="Hospitality Solutions">Hospitality Solutions</option>
                  </select>
                </div>
              </div>

              {/* Right Side Form Fields */}
              <div className="space-y-6">
                {/* Best Time of Day for Appointment */}
                <div className="flex flex-col">
                  <label htmlFor="timeOfDay" className="text-left text-gray-700 font-semibold">Best Time of Day for Your Appointment *</label>
                  <select
                    id="timeOfDay"
                    value={formData.timeOfDay}
                    onChange={handleChange}
                    className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:border-primaryBlue"
                    required
                  >
                    <option value="">Select Time</option>
                    <option value="Morning">Morning</option>
                    <option value="Afternoon">Afternoon</option>
                    <option value="Evening">Evening</option>
                  </select>
                </div>

                {/* Reach Method (Radio buttons side by side) */}
                <div className="flex flex-col">
                  <label className="text-left text-gray-700 font-semibold">Best Way to Reach You *</label>
                  <div className="flex space-x-6">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="reachMethod"
                        value="Phone"
                        onChange={(e) => setFormData({ ...formData, reachMethod: e.target.value })}
                        className="form-radio"
                        required
                        checked={formData.reachMethod === 'Phone'}

                      />
                      <span className="ml-2">Phone</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="reachMethod"
                        value="Email"
                        onChange={(e) => setFormData({ ...formData, reachMethod: e.target.value })}
                        className="form-radio"
                        required
                        checked={formData.reachMethod === 'Email'}

                      />
                      <span className="ml-2">Email</span>
                    </label>
                  </div>
                </div>

                {/* Days Available (Checkboxes) */}
                <div className="flex flex-col">
                  <label className="text-left text-gray-700 font-semibold">Days of the Week Available *</label>
                  <div className="grid grid-cols-2 gap-4">
                    {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                      <label className="inline-flex items-center" key={day}>
                        <input
                          type="checkbox"
                          value={day}
                          onChange={handleCheckboxChange}
                          className="form-checkbox"
                        />
                        <span className="ml-2">{day}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>

              {/* Submit and Clear Buttons (Centered) */}
              <div className="col-span-1 md:col-span-2 flex justify-center space-x-4 mt-6">
                <button type="submit" className="bg-primaryBlue text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-all">
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => setFormData({
                    name: '',
                    email: '',
                    projectTitle: '',
                    consultationInterest: '',
                    timeOfDay: '',
                    reachMethod: 'Phone', // Set default value here
                    daysAvailable: [],
                    phone: '', // Added phone reset

                  })}
                  className="bg-gray-500 text-white px-6 py-3 rounded-lg hover:bg-gray-600 transition-all"
                >
                  Clear
                </button>
              </div>
            </form>

            {/* Status Messages */}
            {status === 'loading' && <p className="text-blue-500 mt-4">Submitting...</p>}
            {status === 'success' && <p className="text-green-500 mt-4">Form submitted successfully!</p>}
            {status === 'error' && <p className="text-red-500 mt-4">{errorLog || 'Please fill in all required fields.'}</p>}

            </div>
          
          {/* Location Details */}
          <div className="mt-12 bg-gray-50 p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-primaryBlue mb-4">Our Location</h3>
            <p className="text-lg text-gray-700">We are located at:</p>
            <div className="mt-6 space-y-4">
              <p><strong>Address:</strong>GTA Toronto, ON, CA</p>
              <p><strong>Email:</strong> info@blueblockss.com</p>
              <p><strong>Phone:</strong> +1 226 552 0083</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default BookConsultancyForm;

