// src/components/ServiceCard.js

import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion"; // Import motion from framer-motion

const ServiceCard = ({ title, description, points, image, reverse }) => {
  // Define the variants for the animation
  const variants = {
    hidden: (reverse) => ({
      opacity: 0,
      x: reverse ? 100 : -100, // Comes from right if reverse, from left if not
    }),
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <motion.div
      className={`flex flex-col ${reverse ? "md:flex-row-reverse" : "md:flex-row"} items-center`}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }} // Only animate once, when 30% of the element is in view
      custom={reverse} // Pass reverse to the variants for conditional animation
      variants={variants} // Apply the animation variants
    >
      {/* Image Section */}
      <div className="md:w-1/2 p-6">
        <div className="bg-gray-300 w-full h-64 md:h-80 rounded-lg flex items-center justify-center">
          {/* Replace with actual image */}
          {/* <p className="text-gray-500">Image Placeholder (640x360)</p> */}
          <img src={image} />

        </div>
      </div>

      {/* Text Section */}
      <div className="md:w-1/2 p-6">
        <h3 className="text-3xl font-semibold text-blue-600 mb-4">{title}</h3>
        <p className="text-gray-700 mb-4">{description}</p>
        <ul className="text-gray-700 list-disc pl-6 space-y-2">
          {points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

ServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.string.isRequired,
  reverse: PropTypes.bool
};

export default ServiceCard;
