// src/pages/HospitalityProductDetails.js

import React from "react";
import Layout from "../components/Layout/Layout";
import ProductFeatureCard from "../components/services/ProductFeatureCard"; // Import ProductFeatureCard component
import {
  FaConciergeBell,
  FaThermometerHalf,
  FaUtensils,
  FaHotel,
  FaCalendarCheck,
  FaBullhorn,
  FaClipboardCheck,
  FaBars,
  FaChartLine,
  FaHandHoldingHeart,
  FaCheckCircle,
  FaComments
} from "react-icons/fa";

const hospitalityData = [
  {
    title: "Hospitality Management Software",
    description: [
      "The integration of hospitality management software has revolutionized the way hotels deliver in-room entertainment and information services. By deploying a software hotel management solution that features tablet-based systems in each guest room and a centralized management console, hotels can provide guests with a highly personalized and immersive experience.",
      "Discover the best hotel software on the market and take your hotel to the next level of success. Experience the power of hospitality management software and transform your hotel into a beacon of excellence in the industry."
    ],
    image: "path_to_hospitality_image_1" // Placeholder image path
  },
  {
    title: "Hotel Management Software",
    description: [
      "A hotel software system is a game-changer in the world of hotel management. With its user-friendly interface and comprehensive suite of features, it's quickly becoming the go-to choice for hospitality professionals.",
      "It simplifies operations, streamlines workflows, and optimizes performance across all departments."
    ],
    image: "path_to_hospitality_image_2" // Placeholder image path
  }
];

// Features List using ProductFeatureCard with icons
const featuresList = [
  { title: "Room Service", description: "Blankets, towels, etc.", icon: <FaConciergeBell /> },
  { title: "Dynamic Food", description: "One-touch ordering.", icon: <FaUtensils /> },
  { title: "Room Temperature", description: "A/C controls.", icon: <FaThermometerHalf /> },
  { title: "Hotel Information", description: "Web, pictures, video.", icon: <FaHotel /> },
  { title: "Appointment Management", description: "Spa, etc.", icon: <FaCalendarCheck /> },
  { title: "Advertisement Publication", description: "Tour company, rental, etc.", icon: <FaBullhorn /> },
  { title: "Customer Survey Forms", description: "Collect guest feedback.", icon: <FaClipboardCheck /> }
];

// Management and Monitoring points with icons
const managementPoints = [
  {
    title: "Flexible Menu Design",
    description: "Different menus can be designed for various guest groups, with functions tailored to each.",
    icon: <FaBars />
  },
  {
    title: "Real-time Feedback",
    description: "Enables hotels to monitor guest requests and survey responses in real-time from a centralized location.",
    icon: <FaComments />
  },
  {
    title: "Unserved Demand Tracking",
    description: "Keep track of unserved demands to address outstanding requests promptly.",
    icon: <FaHandHoldingHeart />
  },
  {
    title: "Retrospective Analysis",
    description: "Generates reports that help identify areas for improvement based on past performance.",
    icon: <FaChartLine />
  },
  {
    title: "Integration with Hotel Solutions",
    description: "Easily integrates with existing hotel management systems for seamless operations.",
    icon: <FaCheckCircle />
  }
];

const HospitalityProductDetails = () => {
  return (
    <Layout>
      {/* Hero Section */}
      <section className="relative bg-gray-100 py-12">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{ backgroundImage: "url('path_to_hero_image')" }}
        >
          <div className="bg-primaryBlue bg-opacity-70 h-full w-full"></div>
        </div>
        <div className="relative z-10 max-w-screen-xl mx-auto text-center py-12">
          <h1 className="text-5xl font-bold text-white mb-4">
            Hospitality Management Software
          </h1>
          <p className="text-lg text-white">
            Elevate guest satisfaction with our Hospitality Software. Our
            all-in-one Hospitality Solution offers in-room entertainment,
            information, and management services, powered by advanced
            hospitality management software.
          </p>
          <button className="mt-6 px-6 py-3 bg-white text-primaryBlue font-semibold rounded-full shadow-lg">
            Contact Us
          </button>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto space-y-12">
          {hospitalityData.map((data, index) => (
            <div
              key={index}
              className={`flex flex-col lg:flex-row items-center ${
                index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
              }`}
            >
              <div className="w-full lg:w-1/2 p-6">
                <img
                  src={data.image} // Placeholder path
                  alt={data.title}
                  className="w-full h-80 bg-gray-300 rounded-lg shadow-lg"
                />
              </div>
              <div className="w-full lg:w-1/2 p-6 space-y-4">
                <h2 className="text-3xl font-bold text-primaryBlue">
                  {data.title}
                </h2>
                {data.description.map((paragraph, pIndex) => (
                  <p key={pIndex} className="text-gray-600 leading-relaxed">
                    {paragraph}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Features List using ProductFeatureCard */}
      <section className="py-12 bg-gray-100">
        <div className="max-w-screen-lg mx-auto text-center">
          <h3 className="text-3xl font-bold text-primaryBlue mb-6">
            Features List
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {featuresList.map((feature, index) => (
              <ProductFeatureCard
                key={index}
                title={feature.title}
                description={[feature.description]}
                icon={feature.icon}
                isOpen={true} // Always open, no expand icon
                onClick={() => {}} // No functionality for expanding
              />
            ))}
          </div>
        </div>
      </section>

      {/* Management and Monitoring Section */}
      <section className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto">
          <h3 className="text-3xl font-bold text-center text-primaryBlue mb-8">
            Management And Monitoring
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {managementPoints.map((point, index) => (
              <div key={index} className="bg-gray-100 p-6 rounded-lg shadow flex items-start space-x-4">
                <div className="text-primaryBlue text-3xl">{point.icon}</div>
                <div>
                  <h4 className="text-xl font-semibold text-primaryBlue mb-2">
                    {point.title}
                  </h4>
                  <p className="text-gray-600">{point.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default HospitalityProductDetails;
