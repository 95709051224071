import React from "react";
import Nav from "../nav/nav";
import Footer from "../footer/footer";


function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Navigation */}
      <Nav />

      {/* Main Content */}
      <main className="flex-grow bg-secondaryWhite p-6">
        {children}
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Layout;
