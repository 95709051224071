import React from "react";
import { motion } from "framer-motion";
import {
  FaCloud,
  FaCogs,
  FaDatabase,
  FaBrain,
  FaNetworkWired,
  FaTools,
  FaCode,
  FaProjectDiagram,
} from "react-icons/fa";
import { Link } from "react-router-dom";

function ConsultingFocus() {
  // Animation variants for cards
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" },
    },
  };

  return (
    <section className="py-16 bg-gray-100" id="consulting-focus">
      <div className="max-w-screen-xl mx-auto text-center px-4">
        <h2 className="text-4xl font-bold text-primaryBlue mb-12">
          Areas of Our Consulting Focus
        </h2>

        {/* Consulting Areas Grid with Animation */}
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
          <Link to="./Book-consultancy">
            {/* AI (Artificial Intelligence) */}
            <motion.div
              className="bg-white rounded-lg shadow-lg p-6 transition-shadow hover:shadow-2xl"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <FaBrain className="text-primaryBlue text-4xl mb-4" />
              <h3 className="text-2xl font-semibold text-primaryBlue mb-4">
                AI (Artificial Intelligence)
              </h3>
              <p className="text-gray-700">
                Harness the power of AI to automate tasks, make data-driven
                decisions, and improve business operations.
              </p>
            </motion.div>
          </Link>
          <Link to="./Book-consultancy">
            {/* Machine Learning */}
            <motion.div
              className="bg-white rounded-lg shadow-lg p-6 transition-shadow hover:shadow-2xl"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <FaCogs className="text-primaryBlue text-4xl mb-4" />
              <h3 className="text-2xl font-semibold text-primaryBlue mb-4">
                Machine Learning (ML)
              </h3>
              <p className="text-gray-700">
                Implement advanced ML algorithms to predict trends, optimize
                processes, and enhance business insights.
              </p>
            </motion.div>
          </Link>
          {/* Big Data */}
          <motion.div
            className="bg-white rounded-lg shadow-lg p-6 transition-shadow hover:shadow-2xl"
            variants={cardVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <Link to="./Book-consultancy">
              <FaDatabase className="text-primaryBlue text-4xl mb-4" />
              <h3 className="text-2xl font-semibold text-primaryBlue mb-4">
                Big Data
              </h3>
              <p className="text-gray-700">
                Leverage Big Data solutions to analyze large datasets and
                extract valuable insights to fuel growth.
              </p>
            </Link>
          </motion.div>
          <Link to="./Book-consultancy">
            {/* IoT (Internet of Things) */}
            <motion.div
              className="bg-white rounded-lg shadow-lg p-6 transition-shadow hover:shadow-2xl"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <FaNetworkWired className="text-primaryBlue text-4xl mb-4" />
              <h3 className="text-2xl font-semibold text-primaryBlue mb-4">
                IoT (Internet of Things)
              </h3>
              <p className="text-gray-700">
                Integrate IoT solutions to connect devices, improve efficiency,
                and gain real-time data from operations.
              </p>
            </motion.div>
          </Link>
          <Link to="./Book-consultancy">
            {/* ERP */}
            <motion.div
              className="bg-white rounded-lg shadow-lg p-6 transition-shadow hover:shadow-2xl"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <FaTools className="text-primaryBlue text-4xl mb-4" />
              <h3 className="text-2xl font-semibold text-primaryBlue mb-4">
                ERP
              </h3>
              <p className="text-gray-700">
                Optimize your business processes and improve resource management
                with our ERP consulting services.
              </p>
            </motion.div>
          </Link>
          <Link to="./Book-consultancy">
            {/* Cloud Computing */}
            <motion.div
              className="bg-white rounded-lg shadow-lg p-6 transition-shadow hover:shadow-2xl"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <FaCloud className="text-primaryBlue text-4xl mb-4" />
              <h3 className="text-2xl font-semibold text-primaryBlue mb-4">
                Cloud Computing
              </h3>
              <p className="text-gray-700">
                Transition to the cloud for flexible, scalable, and
                cost-effective solutions that grow with your business.
              </p>
            </motion.div>
          </Link>
          <Link to="./Book-consultancy">
            {/* DevOps */}
            <motion.div
              className="bg-white rounded-lg shadow-lg p-6 transition-shadow hover:shadow-2xl"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <FaCode className="text-primaryBlue text-4xl mb-4" />
              <h3 className="text-2xl font-semibold text-primaryBlue mb-4">
                DevOps
              </h3>
              <p className="text-gray-700">
                Implement DevOps strategies to streamline development and
                operations, ensuring faster delivery and better collaboration.
              </p>
            </motion.div>
          </Link>
          <Link to="./Book-consultancy">
            {/* System Design */}
            <motion.div
              className="bg-white rounded-lg shadow-lg p-6 transition-shadow hover:shadow-2xl"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <FaProjectDiagram className="text-primaryBlue text-4xl mb-4" />
              <h3 className="text-2xl font-semibold text-primaryBlue mb-4">
                System Design
              </h3>
              <p className="text-gray-700">
                Design complex systems that are scalable, resilient, and
                tailored to meet your organization’s specific needs.
              </p>
            </motion.div>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default ConsultingFocus;
